/* App.css */

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.App-header {
  margin-bottom: 20px;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

label {
  margin-right: 10px;
}

input, select {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
}

button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
